
import Vue from 'vue';

export default Vue.extend({
  components: {
    googleAuthNew: () => import('../admin/components/googleAuthNew.vue'),
    AppGlobals: () => import('../global/globals.vue'),
  },
  data: (): {} => ({}),
  mounted(): void {
    window.parent.postMessage({ action: 'onLoadedNew' }, '*');
  },
  // mounted(): void {},
  methods: {
    loadAgency(message: string): void {
      // notify parent to update list of agencies
      window.parent.postMessage({ action: 'loadAgencyNew', message }, '*');
    },
    onError(error: string): void {
      window.parent.postMessage({ action: 'onErrorNew', error }, '*');
    },
    onExistingAccount(message: string): void {
      window.parent.postMessage({ action: 'onExistingAccount', message }, '*');
    },
  },
  computed: {
    isFramed(): string | null {
      if (this.$route.query.framed) return 'framed';
      return null;
    },
  },
});
